import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import authApi from '@redux/api/Auth/AuthService'

import type { IUser } from '@redux/types/global'
import type { RootState } from '@redux/store'

interface IAuthState {
  isAuth: boolean
  user: IUser | null
  token: string | null
  refresh: string | null
  cp_token: string | null
  remember: boolean
}

const token =
  localStorage.getItem('token') || sessionStorage.getItem('token') || null

const refresh = localStorage.getItem('refresh') || null

const initialState: IAuthState = {
  isAuth: false,
  user: null,
  token,
  refresh,
  cp_token: null,
  remember: false,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateTokens(
      state,
      action: PayloadAction<{ token: string; refresh: string }>,
    ) {
      state.token = action.payload.token
      state.refresh = action.payload.refresh

      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('refresh', action.payload.refresh)
    },
    logout(state) {
      state.isAuth = false
      state.user = null
      state.token = null

      localStorage.removeItem('token')
      localStorage.removeItem('refresh')
      sessionStorage.removeItem('token')
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload, meta }) => {
          switch (payload.loginType) {
            case 'native': {
              state.isAuth = true
              state.user = payload.user
              state.token = payload.token
              state.refresh = payload.refresh

              if (meta.arg.originalArgs.remember) {
                localStorage.setItem('token', payload.token)
                localStorage.setItem('refresh', payload.refresh)
              } else {
                sessionStorage.setItem('token', payload.token)
              }
              break
            }
            case 'initial': {
              state.remember = meta.arg.originalArgs.remember
              state.cp_token = payload.cp_token
              break
            }
            default:
              break
          }
        },
      )
      .addMatcher(
        authApi.endpoints.verifyCPToken.matchFulfilled,
        (state, { meta }) => {
          state.cp_token = meta.arg.originalArgs
          state.remember = true
        },
      )
      .addMatcher(
        authApi.endpoints.changePassword.matchFulfilled,
        (state, { payload }) => {
          state.isAuth = true
          state.user = payload.user
          state.token = payload.token
          state.refresh = payload.refresh

          if (state.remember) {
            localStorage.setItem('token', payload.token)
            localStorage.setItem('refresh', payload.refresh)
          } else {
            sessionStorage.setItem('token', payload.token)
          }
        },
      )
      .addMatcher(
        authApi.endpoints.checkCredentials.matchFulfilled,
        (state, { payload }) => {
          state.isAuth = true
          state.user = payload.user
        },
      )
      .addMatcher(authApi.endpoints.checkCredentials.matchRejected, () => {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh')
        sessionStorage.removeItem('token')
      })
  },
})

export const { logout, updateTokens } = authSlice.actions
export const getAuthorizationToken = (state: RootState) => state.auth.token
export default authSlice.reducer
