import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

import { store } from '@redux/store'
import entrySplitApi from '@redux/api/enrty'

import type { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type { EntrySplitApiReducerPath } from '@redux/api/enrty'
import type {
  ChangePasswordBody,
  CheckCredentialsResponse,
  LoginBody,
  ChangePasswordResponse,
  LoginResponse,
  SuccessfullyResponse,
  SendMailAuthorizationLinkBody,
  RefreshAccessTokenResponse,
} from '@redux/types/types'
import type { RootState } from '@redux/store'

type AuthApiTagTypes = any

const authApi = entrySplitApi.injectEndpoints({
  endpoints: (
    builder: EndpointBuilder<
      ReturnType<typeof fetchBaseQuery>,
      AuthApiTagTypes,
      EntrySplitApiReducerPath
    >,
  ) => ({
    login: builder.mutation<LoginResponse, LoginBody>({
      query: (body) => ({
        url: 'auth/login',
        method: 'POST',
        body,
      }),
    }),
    verifyCPToken: builder.query<SuccessfullyResponse, string>({
      query: (cpt) => ({
        url: 'auth/account/verify',
        headers: {
          'Authorization': `Bearer ${cpt}`,
        },
      }),
    }),
    refreshAccessToken: builder.query<RefreshAccessTokenResponse, string>({
      query: (refresh) => ({
        url: 'auth/refresh',
        headers: {
          'Authorization': `Bearer ${refresh}`,
        },
      }),
    }),
    changePassword: builder.mutation<
      ChangePasswordResponse,
      ChangePasswordBody
    >({
      query: (body) => ({
        url: 'auth/change-password',
        method: 'POST',
        body,
        headers: {
          'Authorization': `Bearer ${
            (store.getState() as RootState).auth.cp_token
          }`,
        },
      }),
    }),
    checkCredentials: builder.query<CheckCredentialsResponse, void>({
      query: () => 'auth/check-credentials',
    }),
    sendMailAuthorizationLink: builder.mutation<
      SuccessfullyResponse,
      SendMailAuthorizationLinkBody
    >({
      query: (body) => ({
        url: 'auth/welcome',
        method: 'POST',
        body,
      }),
    }),
  }),
})

entrySplitApi.enhanceEndpoints({
  addTagTypes: [],
})

export const {
  useLoginMutation,
  useVerifyCPTokenQuery,
  useChangePasswordMutation,
  useCheckCredentialsQuery,
  useSendMailAuthorizationLinkMutation,
} = authApi

export default authApi
