import {
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { toastConfig } from '@config/toast'
import authSlice from '@redux/slice/authSlice'
import officeSlice from '@redux/slice/officeSlice'

import type { TypedUseSelectorHook } from 'react-redux'
import entrySplitApi from '@redux/api/enrty'

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    toast.error(
      action?.payload?.data?.message || 'Что-то пошло не так!',
      toastConfig,
    )
  }

  return next(action)
}

export const store = configureStore({
  reducer: {
    [entrySplitApi.reducerPath]: entrySplitApi.reducer,
    auth: authSlice,
    offices: officeSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      entrySplitApi.middleware,
      rtkQueryErrorLogger,
    ),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
