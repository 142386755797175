import React from 'react'

export const Spin = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M24 12C24 14.6416 23.1284 17.2094 21.5202 19.3051C19.9121 21.4009 17.6574 22.9074 15.1058 23.5911C12.5542 24.2748 9.84833 24.0975 7.4078 23.0866C4.96727 22.0757 2.9285 20.2877 1.6077 18C0.28689 15.7123 -0.242138 13.0527 0.102662 10.4337C0.447461 7.81467 1.64682 5.38262 3.51472 3.51472C5.38262 1.64682 7.81467 0.447461 10.4337 0.102662C13.0527 -0.242138 15.7123 0.286889 18 1.60769L16.2 4.72539C14.5986 3.80082 12.7369 3.4305 10.9036 3.67186C9.07027 3.91322 7.36783 4.75277 6.0603 6.0603C4.75277 7.36783 3.91322 9.07027 3.67186 10.9036C3.4305 12.7369 3.80082 14.5986 4.72539 16.2C5.64995 17.8014 7.07709 19.053 8.78546 19.7606C10.4938 20.4682 12.388 20.5924 14.1741 20.1138C15.9602 19.6352 17.5385 18.5806 18.6642 17.1136C19.7898 15.6466 20.4 13.8491 20.4 12H24Z'
      fill='url(#paint0_linear_91_2208)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_91_2208'
        x1='0.5'
        y1='6.5'
        x2='33'
        y2='28'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#4844E2' />
        <stop offset='1' stopColor='#4844E2' stopOpacity='0' />
      </linearGradient>
    </defs>
  </svg>
)
