import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { logout, updateTokens } from '@redux/slice/authSlice'

import type { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import type { RootState } from '@redux/store'
import type { RefreshAccessTokenResponse } from '@redux/types/types'

const REDUCER_PATH = 'entrySplitApi'
const BASE_URL = 'https://justice-portal.ru/api/'

export type EntrySplitApiReducerPath = typeof REDUCER_PATH

const authorizationHeaders: FetchBaseQueryArgs['prepareHeaders'] = (
  headers,
  { getState },
) => {
  const { token } = (getState() as RootState).auth

  const authorization = headers.get('Authorization')

  if (!authorization) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  return headers
}

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: authorizationHeaders,
})

const baseQueryWithReauth: ReturnType<typeof fetchBaseQuery> = async (
  args,
  api,
  extraOptions,
) => {
  const { refresh } = (api.getState() as RootState).auth

  let response = await baseQuery(args, api, extraOptions)

  if (refresh && response.error && response.error.status === 498) {
    const refreshResult = await baseQuery(
      {
        url: 'auth/refresh',
        method: 'GET',
        headers: { Authorization: `Bearer ${refresh}` },
      },
      api,
      extraOptions,
    )

    if (refreshResult?.data) {
      api.dispatch(
        updateTokens({
          token: (refreshResult.data as RefreshAccessTokenResponse).token,
          refresh: (refreshResult.data as RefreshAccessTokenResponse).refresh,
        }),
      )

      response = await baseQuery(args, api, extraOptions)
    } else {
      api.dispatch(logout())
    }
  }

  return response
}
const entrySplitApi = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})

export default entrySplitApi
