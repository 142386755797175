import React, { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { Spinner } from '@components/Spinner/Spinner'

import { useCheckCredentialsQuery } from '@redux/api/Auth/AuthService'
import { useAppSelector } from '@redux/store'
import { useIsAdmin } from '@hooks/useIsAdmin'
import { getAuthorizationToken } from '@redux/slice/authSlice'

const MainLayout = React.lazy(() =>
  import('@pages/MainLayout/MainLayout').then((module) => ({
    default: module.MainLayoutRoute,
  })),
)
const EmptyLayout = React.lazy(() =>
  import('@pages/EmptyLayout/EmptyLayout').then((module) => ({
    default: module.EmptyLayoutRoute,
  })),
)
const NominationsPage = React.lazy(() =>
  import('@pages/NominationsPage/NominationsPage').then((module) => ({
    default: module.NominationsPageRoute,
  })),
)
const HonorPage = React.lazy(() =>
  import('@pages/HonorPage/HonorPage').then((module) => ({
    default: module.HonorPageRoute,
  })),
)
const NominationPage = React.lazy(() =>
  import('@pages/NominationPage/NominationPage').then((module) => ({
    default: module.NominationPageRoute,
  })),
)
const VotesPage = React.lazy(() =>
  import('@pages/VotesPage/VotesPage').then((module) => ({
    default: module.VotesPageRoute,
  })),
)
const DetailsPage = React.lazy(() =>
  import('@pages/DetailsPage/DetailsPage').then((module) => ({
    default: module.DetailsPageRoute,
  })),
)
const UsersPage = React.lazy(() =>
  import('@pages/UsersPage/UsersPage').then((module) => ({
    default: module.UsersPageRoute,
  })),
)
const NotFoundPage = React.lazy(() =>
  import('@pages/NotFoundPage/NotFoundPage').then((module) => ({
    default: module.NotFoundPageRoute,
  })),
)
const LoginPage = React.lazy(() =>
  import('@pages/LoginPage/LoginPage').then((module) => ({
    default: module.LoginPageRoute,
  })),
)

export type ProtectedRouteProps = {
  access: boolean
  navigateTo?: string
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  access = false,
  navigateTo,
  children,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!access) {
      if (navigateTo) navigate(navigateTo, { replace: true })
      else navigate('/not-found', { replace: true })
    }
  }, [access])

  if (!access) return null

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const Router = () => {
  const isAuth = useAppSelector((state) => state.auth.isAuth)
  const token = useAppSelector(getAuthorizationToken)
  const isAdmin = useIsAdmin()

  const credentials = useCheckCredentialsQuery(undefined, {
    skip: !token || (!!token && isAuth),
  })

  if (credentials.isFetching) return <Spinner />

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route
          path='/'
          element={<MainLayout access={isAuth} navigateTo='/login' />}
        >
          <Route index element={<NominationsPage access result={false} />} />
          <Route path='/honor' element={<HonorPage access result />} />
          <Route
            path='/nominations/:nominationId/nominee'
            element={<NominationPage access />}
          />
          <Route
            path='/nominations/:nominationId/nominee/:nomineeId/votes'
            element={<VotesPage access={isAdmin} />}
          />
          <Route
            path='/nominations/:nominationId/nominee/:nomineeId/votes/:userId'
            element={<DetailsPage access={isAdmin} />}
          />
          <Route path='/users' element={<UsersPage access={isAdmin} />} />
        </Route>
        <Route
          path='/login'
          element={<EmptyLayout access={!isAuth} navigateTo='/' />}
        >
          <Route index element={<LoginPage access />} />
        </Route>
        <Route path='*' element={<NotFoundPage access />} />
      </Routes>
    </Suspense>
  )
}
