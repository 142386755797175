import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from '@redux/store'

import App from './App'

import 'react-toastify/dist/ReactToastify.css'
import 'slick-carousel/slick/slick.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import './styles/main.scss'

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)
