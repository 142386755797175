import React from 'react'
import { ToastContainer } from 'react-toastify'

import { Router } from '@routes/Router'

const App = () => (
  <>
    <ToastContainer />
    <Router />
  </>
)

export default App
