import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUserOffice } from '@redux/types/global'

interface IOfficeState {
  office: IUserOffice[]
}

const initialState: IOfficeState = {
  office: [],
}

const officeSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    allOffices(state, action: PayloadAction<{ office: [] }>,
    ) {
      state.office = action.payload.office
    },
  },
})

export const { allOffices } = officeSlice.actions
export default officeSlice.reducer
