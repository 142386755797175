import { ToastOptions } from 'react-toastify'

export const toastConfig: ToastOptions = {
  position: 'top-left',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  pauseOnFocusLoss: true,
  draggable: true,
  theme: 'light',
}
