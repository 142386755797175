import React from 'react'
import ReactDOM from 'react-dom'

import { Spin } from '@components/Icons/Spin'

import './Spinner.scss'

type SpinnerPortalProps = {
  wrapperId?: string
  children: React.ReactNode | null
}

type SpinnerProps = {
  wrapperId?: string
}

export const SpinnerPortal: React.FC<SpinnerPortalProps> = ({
  wrapperId = 'spinner-portal-wrapper',
  children,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const element = document.getElementById(wrapperId!)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!wrapperId && !element) return <>{children}</>

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return ReactDOM.createPortal(children, element!)
}

export const Spinner: React.FC<SpinnerProps> = ({ wrapperId }) => (
  <SpinnerPortal wrapperId={wrapperId}>
    <div className='spinner'>
      <Spin />
    </div>
  </SpinnerPortal>
)
